
const MarkerTooltip = (props) => {
    const plantaInfo = props.plantaInfo
    return (
        <>
            <strong>{plantaInfo.nombre} ({plantaInfo.capacidadInstalada} kWp)</strong>
            {/*
            <br /><br />
            Total: {(plantaInfo.generadoTotal !== undefined)? plantaInfo.generadoTotal: ''} (kWh)
            <br />
            Diaria: {(plantaInfo.generadoDia !== undefined)? plantaInfo.datos.generadoDia: ''} (kWh)
            <br />
            Mensual: {(plantaInfo.generadoMes !== undefined)? plantaInfo.generadoMes: ''} (kWh)
            */}
        </>
    )
}

export default MarkerTooltip