import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

import "./css/Documentos.css"
import Header from './Header';
import Footer from './Footer';
import LoaderSpinner from './LoaderSpinner';
import ClienteREST from '../util/ClienteREST';

import useAuthToken from "../util/TokenUtils";

const Documentos = () => {
    const [cookies] = useCookies()
    const [loading, setLoading] = useState(true)
    const [plantas, setPlantas] = useState([])
    const navigate = useNavigate()
    const [authToken] = useAuthToken()
    const toast = useRef()

    useEffect(() => {
        if (cookies.userInfo === undefined || !cookies.userInfo.isLoggedIn) {
            navigate('/');
        } else {
            const clienteRest = new ClienteREST()
            setLoading(true)
            clienteRest.getDocumentosPorCliente(cookies.userInfo.user.clienteId, authToken)
                .then(res => res.json())
                .then(dataPlantas => {
                    setPlantas(dataPlantas)
                    setLoading(false)
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: 'Error: ', detail: 'No es posible conectarse al endpoint.'})
                    setLoading(false)
                })
        }
    }, [cookies, navigate, authToken])

    const accionesTemplate = (rowData) => {
        if (rowData.documentos.length === 0)
            return (
                <div className="row ">
                    <div className="col-md-auto">
                        <p>Sin documentos</p>
                    </div>
                </div>
            )

        return (
            <>
                {rowData.documentos.map((o, i) => {
                    return (
                        <div className="row documento-row" key={o.id}>
                            <div className="col-md-auto">
                                <button onClick={() => {
                                    setLoading(true)
                                    //window.location.href = o.url
                                    window.open(o.url, '_blank')
                                    setLoading(false)                        
                                }} className="btn btn-success fa-solid fa-file-pdf" />
                            </div>
                            <div className="col-md-auto">
                                <small>{o.tipo}</small>
                            </div>
                            <div className="col">
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <Header />
            <Toast ref={toast} position="top-right" />
            <div className="container">
                <br />
                <h3 className="column-title">Documentos</h3>
                <p>Aquí encontrarás toda la documentación asociada a las plantas instaladas, haz click en los botones de la derecha para descargarlos.</p>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTable value={plantas} responsiveLayout="scroll" stripedRows>
                            <Column field="nombre" header="Planta" />
                            <Column header="Documentos" body={accionesTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <Footer />
            <LoaderSpinner show={loading} />
        </>
    )
}

export default Documentos;