import React from 'react';
import ReactDOM from 'react-dom/client';
import Login from './components/Login';
import Plantas from './components/Plantas';
import Documentos from './components/Documentos';
import Logout from './components/Logout';

import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
					<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
						<Login />
					</GoogleReCaptchaProvider>
				} />
                <Route path="/plantas" element={<Plantas apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} />} />
                <Route path="/documentos" element={<Documentos />} />
                <Route path="/logout" element={<Logout />} />
            </Routes>  
        </BrowserRouter>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
