import { useState } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const Header = () => {
    const [cookies] = useCookies()
    const [name, setName] = useState()
    //const [picture, setPicture] = useState()

    useEffect(() => {
        if (cookies.userInfo !== undefined) {
            setName(cookies.userInfo.user.name)
            //setPicture(cookies.userInfo.user.picture)
        }
    //}, [cookies, setName, setPicture])
    }, [cookies, setName])

    return (
        <header id="header" className="header-one" >
            <div className="container-fluid site-navigation">
                <div className="row">
                    <div className="col-3 logo-texto">
                        <img src="img/texto-vigorsolar.png" alt="" width="200" />
                    </div>
                    <div className="col-4">
                        <nav className="navbar navbar-expand-lg navbar-dark p-0">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            
                            <div id="navbar-collapse" className="collapse navbar-collapse">
                                <ul className="nav navbar-nav mr-auto">
                                    <li className="nav-item"><a className="nav-link" href="/plantas">Plantas</a></li>
                                    <li className="nav-item"><a className="nav-link" href="/documentos">Documentos</a></li>
                                    <li className="nav-item"><a className="nav-link" href="/logout">Salir</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="col justify-content-end d-flex user-info">
                        <span>
                            {
                                //<img className="rounded-circle" src={picture} alt={name} height="40"/>
                            }
                            {name}
                        </span>
                    </div>
                </div> 
            </div>
        </header>
    )
}

export default Header