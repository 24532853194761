
export default function LoaderSpinner(data) {
    if (data.show) {
        return (
            <>
                <div className="loading"></div>
            </>
        )
    } else {
        return (
            <>
                <div className="loading" style={{display: 'none'}}></div>
            </>
        )
    }
}