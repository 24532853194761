
class ClienteREST {

    login(usuario, password, captchaToken) {
        const loginEndpoint = process.env.REACT_APP_API_ENDPOINT_URL+"/login"
   
        return fetch(loginEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                usuario: usuario,
                password: password,
                captchaToken: captchaToken
            })
        })
    }

    async loginGoogle(token) {
        const loginGoogleEndpoint = process.env.REACT_APP_API_ENDPOINT_URL+"/loginGoogle"
        return await fetch(loginGoogleEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
            })
        })
    }

    async getPlantas(clienteId, token) {
        const plantasEndpoint = process.env.REACT_APP_API_ENDPOINT_URL+"/plantas/cliente/"+clienteId
        return await fetch(plantasEndpoint, {
            headers: {
                'Authorization': 'Bearer '+ token
            }
        })
    }

    async getDocumentosPorPlanta(plantaId, token) {
        const documentosEndpoint = process.env.REACT_APP_API_ENDPOINT_URL+"/documentos/planta/"+plantaId
        return await fetch(documentosEndpoint, {
            headers: {
                'Authorization': 'Bearer '+ token
            }
        })
    }

    async getDocumentosPorCliente(clienteId, token) {
        const documentosEndpoint = process.env.REACT_APP_API_ENDPOINT_URL+"/documentos/cliente/"+clienteId
        return await fetch(documentosEndpoint, {
            headers: {
                'Authorization': 'Bearer '+ token
            }
        })
    }
}

export default ClienteREST