import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

import LoaderSpinner from "./LoaderSpinner";

const Logout = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [cookies, removeCookie] = useCookies()

    useEffect(() => {
        removeCookie("userInfo")
        setLoading(false)
        navigate('/')
    }, [navigate, removeCookie, cookies])

    return (
        <LoaderSpinner show={loading} />
    )
}

export default Logout