import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader"
import { useCookies } from 'react-cookie';
import { Toast } from 'primereact/toast';

import ClienteREST from '../util/ClienteREST';
import Header from './Header';
import Footer from './Footer';
import LoaderSpinner from './LoaderSpinner';
import MarkerTooltip from './MarkerTooltip';
import ReactDOMServer from 'react-dom/server'
import useAuthToken from "../util/TokenUtils";

const Plantas = (props) => {
    const [cookies] = useCookies();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useRef();
    const [map, setMap] = useState()
    const [plantas, setPlantas] = useState([])
    const [authToken] = useAuthToken()

    const SeleccionarPlanta = () => {
        if (plantas.length === 0)
            return <></>

        return (
            <form style={{position: 'absolute', marginTop: '10px', marginLeft: '10px', zIndex: '1'}}>
                <div className="form-group">
                    <select onChange={(evt) => {
                        if (evt.target.value !== "")  {
                            map.setCenter({
                                lat: plantas[evt.target.value].lat,
                                lng: plantas[evt.target.value].lng
                            })
                        }
                    }} className="form-control">
                    <option value="">-- Seleccione Planta --</option>
                    {plantas.map((o, i) => {
                        return (<option key={i} value={i}>{o.nombre}</option>) 
                    })}
                    </select>
                </div>
            </form>
        )
    }

    useEffect(() => {
        if (cookies.userInfo === undefined || !cookies.userInfo.isLoggedIn) {
            navigate('/');
        } else {
            const mapZoom = 14;

            new Loader({
                apiKey: props.apiKey,
                version: "weekly",
                libraries: ["places"],
            }).load().then((google) => {
        
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: mapZoom,
                    mapTypeId: 'roadmap',
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                      position: google.maps.ControlPosition.LEFT_BOTTOM,
                    }
                });
        
                const clienteRest = new ClienteREST()
                clienteRest.getPlantas(cookies.userInfo.user.clienteId, authToken)
                    .then(res => res.json())
                    .then(dataPlantas => {
                        // solo test
                        //dataPlantas = []
                        setPlantas(dataPlantas)

                        if (dataPlantas.length === 0) {
                            toast.current.show({severity: 'warn', summary: 'Advertencia: ', detail: 'Aún no existen plantas asociadas para este usuario.'})
                            map.setCenter({
                                lat: -33.447487,
                                lng: -70.673676
                            });
                            setLoading(false)
                        } else {
                            const pinIcon = new google.maps.MarkerImage("img/solcito_marker.png", null, null, null, null);
                            dataPlantas.forEach(planta => {
                                let marker = new google.maps.Marker({
                                    position: {
                                        lat: planta.lat,
                                        lng: planta.lng
                                    },
                                    map: map,
                                    icon: pinIcon,
                                    title: planta.nombre
                                });
                                
                                const htmlStr = ReactDOMServer.renderToString(<MarkerTooltip plantaInfo={planta} />);
                                const infoWindow = new google.maps.InfoWindow({
                                    content: htmlStr
                                })
                                const showPlantaInfo = () => {
                                    infoWindow.open(map, marker);
                                };
                                
                                marker.addListener('mouseover', showPlantaInfo);
                                //marker.addListener('mouseout', () => infoWindow.close());
                                marker.addListener('click', showPlantaInfo);

                                const center = {
                                    lat: dataPlantas[0].lat,
                                    lng: dataPlantas[0].lng,
                                }
                                map.setCenter(center);
                            })

                            setLoading(false)
                        }
                    }).catch(() => {
                        toast.current.show({severity: 'error', summary: 'Error: ', detail: 'No es posible conectarse al endpoint.'})
                        setLoading(false)
                    });
                
                
                setMap(map)
            });
        }
    }, [cookies, props, navigate, authToken]);
    
    return (
        <>
            <Header />
            <Toast ref={toast} position="top-right" />
            <SeleccionarPlanta />
            <div id="map" style={{ height: '92.5vh', width: '100%' }}>
			</div>
            <Footer />
            <LoaderSpinner show={loading} />
        </>
    )
}

export default Plantas