import { useCookies } from 'react-cookie';

const useAuthToken = () => {
    const [cookies] = useCookies()
    return [(cookies.userInfo === undefined)? null: cookies.userInfo.user.token]
}

export default useAuthToken


