import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import './css/Login.css';

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCookies } from 'react-cookie';
import { Toast } from 'primereact/toast';
import { useRef, useEffect, useState } from 'react';

import ClienteREST from '../util/ClienteREST';
import LoaderSpinner from './LoaderSpinner';
import { useCallback } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const loadScript = (src) => new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
})

const Login = () => {
    const [cookies, setCookie] = useCookies()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const navigate = useNavigate()
    const toast = useRef()
    const googleButton = useRef(null)
    const [loading, setLoading] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha();

    const setGalleta = useCallback((data) => {
        let d = new Date()
        d.setTime(d.getTime() + (process.env.REACT_APP_COOKIE_DURATION*60*1000))
        // test
        //d.setTime(d.getTime() + (5*1000));}
        setCookie("userInfo", {isLoggedIn: true, user: data}, { path: '/', expires: d, sameSite: 'strict' })
        // eslint-disable-next-line
    }, [setCookie, cookies]);
    
    const handleIdentityCallback = useCallback((data) => {
        setLoading(true)

        const clienteRest = new ClienteREST()
        clienteRest.loginGoogle(data.credential)
            .then(res => {
                if (res.status === 404) {
                    toast.current.show({severity: 'error', summary: 'Error: ', detail: 'Usuario no encontrado en la plataforma.'})
                    setLoading(false)
                    return {'status': 'not-found'}
                } else if (res.status === 400) {
                    toast.current.show({severity: 'error', summary: 'Error: ', detail: 'Token incorrecto.'})
                    setLoading(false)
                    return {'status': 'bad-request'}
                }
                return res.json()
            })
            .then(data => {
                if (data.status === undefined) {
                    setLoading(false)
                    setGalleta(data)
                    navigate('/plantas')
                }
            })
    }, [navigate, setGalleta])
   
    const onSubmit = async (data) => {
        setLoading(true)

        const clienteRest = new ClienteREST()
        const captchaToken = await executeRecaptcha()
        
        clienteRest.login(data.usuario, data.password, captchaToken)
            .then(res => {
                if (res.status === 404) {
                    return {status: 'not-found'}
                }
                return res.json()
            })
            .then(data => {
                if (data.status === 'not-found') {
                    toast.current.show({severity: 'error', summary: 'Error: ', detail: 'Usuario y/o contraseña incorrecta.'})
                } else {
                    const user = {
                        clienteId: data.clienteId,
                        name: data.nombre,
                        picture: null, // sin imagen aun en la BD,
                        token: data.token
                    }
                    setGalleta(user)
                    navigate('/plantas')
                }
                setLoading(false)
        }).catch((e) => {
            toast.current.show({severity: 'error', summary: 'Error: ', detail: 'No se puede conectar al endpoint.'})
            setLoading(false)
        })
    }
    
    useEffect(() => {
        loadScript('https://accounts.google.com/gsi/client').then(() => {
            /*global google*/
            //console.log(google)
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_IDENTITY_CLIENT_ID,
                callback: handleIdentityCallback
            })
            google.accounts.id.renderButton(
                googleButton.current, 
                {theme: 'outline', size: 'medium'} 
            )
        }).catch(console.error)
    }, [handleIdentityCallback])

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <div className="text-center login-form">
                <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
                    <img className="mb-4" src="/img/logo-vigorsolar2.png" alt="" height="100" />
                    <h1 className="h3 mb-3 font-weight-normal">Panel de Clientes</h1>
                    <label htmlFor="usuario" className="sr-only">Usuario</label>
                    <input type="text" autoComplete="off" id="usuario" className="form-control" placeholder="Usuario o correo electrónico" {...register("usuario", { required: true })} />
                    {errors.usuario && <small className="text-danger">Este campo es requerido.</small>}

                    <label htmlFor="password" className="sr-only">Contraseña</label>
                    <input type="password" autoComplete="off" id="password" className="form-control" placeholder="Contraseña" {...register("password", { required: true })} />
                    {errors.password && <small className="text-danger">Este campo es requerido.</small>}

                    <button className="btn btn-lg btn-primary btn-block" type="submit">Ingresar</button>
                    <br />
                    <div className="row">
                        <div className="col-sm-12 d-flex justify-content-center">
                            <div ref={googleButton}></div>
                        </div>
                    </div>
                    <p className="mt-5 mb-3 text-muted">© Vigorsolar SpA. 2021-2022</p>
                    <LoaderSpinner show={loading} />
                </form>
            </div>
        </>
    )
}

export default Login